import { AppNavigation } from '@ms/yammer-features-app-navigation';
import { UserProfileMenuButton } from '@ms/yammer-left-rail';
import { UniversalCreateButtonProvider } from '@ms/yammer-universal-create-button';
import {
  LoadableUserSettingsNav,
  userSettingsGeneralPath,
  userSettingsNotificationsCommunitiesPath,
  userSettingsNotificationsPath,
  userSettingsNotificationsSubscribePath,
  userSettingsNotificationsUnsubscribePath,
  userSettingsRootPath,
} from '@ms/yammer-user-settings-page';
import { useCampaignCreateModal } from '@ms/yammer-web-components/dist/campaignCreateEdit/CampaignCreateModal/hooks/useCampaignCreateModal';
import { useGroupCreateModal } from '@ms/yammer-web-components/dist/groupCreateEdit/LoadableGroupCreateModal';
import NavigationCommunities from '@ms/yammer-web-components/dist/navigation/NavigationCommunities';
import { usePostCreateModal } from '@ms/yammer-web-components/dist/publisher/LoadableUniversalCreatePublisherModal';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useHomeLinkActionButtons } from './hooks';

const DefaultNav: FC = () => {
  const homeLinkActionButtons = useHomeLinkActionButtons();
  const campaignCreateModal = useCampaignCreateModal('UniversalCreateButton');

  return (
    <UniversalCreateButtonProvider
      useGroupCreateModal={useGroupCreateModal}
      campaignCreateModal={campaignCreateModal}
      usePostCreateModal={usePostCreateModal}
    >
      <AppNavigation
        communitiesNavLinksContent={<NavigationCommunities />}
        userProfileLinkActionButtons={[<UserProfileMenuButton key="userProfileMenuButton" />]}
        homeLinkActionButtons={homeLinkActionButtons}
      />
    </UniversalCreateButtonProvider>
  );
};

export const AppLeftNav: React.FC = () => (
  <Switch>
    <Route
      exact={true}
      strict={false}
      path={[
        userSettingsNotificationsCommunitiesPath,
        userSettingsNotificationsPath,
        userSettingsNotificationsSubscribePath,
        userSettingsNotificationsUnsubscribePath,
        userSettingsGeneralPath,
        userSettingsRootPath,
      ]}
    >
      <LoadableUserSettingsNav />
    </Route>
    <Route>
      <DefaultNav />
    </Route>
  </Switch>
);
