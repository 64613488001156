import { useMemo } from 'react';

import { useIsSubNetworksSettingsEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useSubNetworksSettingsAdminPage: UseMainUIFeatureSettings<'subNetworksSettingsAdminPage'> = (options) => {
  const subNetworksAdminSettingsPageSettingsOverrides = options?.overrides?.subNetworksSettingsAdminPage;

  const subNetworksAdminSettingsPageSettingsPolicy = useIsSubNetworksSettingsEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: subNetworksAdminSettingsPageSettingsPolicy,
      ...subNetworksAdminSettingsPageSettingsOverrides,
    }),
    [subNetworksAdminSettingsPageSettingsOverrides, subNetworksAdminSettingsPageSettingsPolicy]
  );
};
