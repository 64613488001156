import {
  getAdminFeatureAccessSettings,
  getAdminFeatureAccessSettingsStatus,
  getCanEditNetworkQuestionSettings,
  getCanEditNetworkQuestionsOnlyMode,
  getViewerCanAccessAnalyticsAdminSettings,
  getViewerCanEditCampaignAnalyticsSettings,
  getViewerCanEditLeaderSettings,
  getViewerCanEditLeadershipAnalyticsSettings,
  getViewerCanEditNetworkQuestionAnalyticsSettings,
  getViewerCanHideUsersForNetworkInDiscovery,
  getViewerCanManageComplianceData,
  getViewerCanManageMultiTenantOrganizationPolicy,
  getViewerCanManageViewOnlyModeUsers,
} from '@ms/yammer-data/dist/domains/adminFeatureAccessSettings/selectors';

import { useExperimentTreatmentWithoutReporting } from '../experiments';
import { useIsInformationBarrierEnabled } from '../informationBarrierSettings';
import { useIsSubNetworksEnabled } from '../subNetworksSettings';
import { useSelector } from '../useSelector';

type UseAdminFeatureAccessSettings = () => ReturnType<typeof getAdminFeatureAccessSettings>;
export const useAdminFeatureAccessSettings: UseAdminFeatureAccessSettings = () =>
  useSelector(getAdminFeatureAccessSettings);

type UseAdminFeatureAccessSettingsStatus = () => ReturnType<typeof getAdminFeatureAccessSettingsStatus>;
export const useAdminFeatureAccessSettingsStatus: UseAdminFeatureAccessSettingsStatus = () =>
  useSelector(getAdminFeatureAccessSettingsStatus);

type UseViewerCanEditAnalyticsSettings = () => boolean;
export const useViewerCanAccessAnalyticsAdminSettings: UseViewerCanEditAnalyticsSettings = () =>
  useSelector(getViewerCanAccessAnalyticsAdminSettings);

type UseViewerCanEditLeadershipAnalyticsSettings = () => boolean;
export const useViewerCanEditLeadershipAnalyticsSettings: UseViewerCanEditLeadershipAnalyticsSettings = () =>
  useSelector(getViewerCanEditLeadershipAnalyticsSettings);

type UseViewerCanEditCampaignAnalyticsSettings = () => boolean;
export const useViewerCanEditCampaignAnalyticsSettings: UseViewerCanEditCampaignAnalyticsSettings = () =>
  useSelector(getViewerCanEditCampaignAnalyticsSettings);

type UseViewerCanEditNetworkQuestionAnalyticsSettings = () => boolean;
export const useViewerCanEditNetworkQuestionAnalyticsSettings: UseViewerCanEditNetworkQuestionAnalyticsSettings = () =>
  useSelector(getViewerCanEditNetworkQuestionAnalyticsSettings);

type UseCanEditNetworkQuestionSettings = () => boolean;
export const useCanEditNetworkQuestionSettings: UseCanEditNetworkQuestionSettings = () =>
  useSelector(getCanEditNetworkQuestionSettings);

type UseCanEditNetworkQuestionsOnlyMode = () => boolean;
export const useCanEditNetworkQuestionsOnlyMode: UseCanEditNetworkQuestionsOnlyMode = () =>
  useSelector(getCanEditNetworkQuestionsOnlyMode);

type UseCanViewerEditLeaderSettings = () => boolean;
export const useCanViewerEditLeaderSettings: UseCanViewerEditLeaderSettings = () =>
  useSelector(getViewerCanEditLeaderSettings);

type UseViewerCanManageComplianceData = () => boolean;
export const useViewerCanManageComplianceData: UseViewerCanManageComplianceData = () =>
  useSelector(getViewerCanManageComplianceData);

type UseAdminCanEditViewOnlyMode = () => boolean;
export const useAdminCanEditViewOnlyMode: UseAdminCanEditViewOnlyMode = () => {
  const isRestrictedUserFlightEnabled = useExperimentTreatmentWithoutReporting('RestrictedUser') === true;
  const viewerCanManageViewOnlyModeUsers = useSelector(getViewerCanManageViewOnlyModeUsers);

  return isRestrictedUserFlightEnabled && viewerCanManageViewOnlyModeUsers;
};

type UseAdminCanHideUsersForNetworkInDiscovery = () => boolean;
export const useAdminCanHideUsersForNetworkInDiscovery: UseAdminCanHideUsersForNetworkInDiscovery = () => {
  const isAdminMuteUserFlightEnabled = useExperimentTreatmentWithoutReporting('AdminMuteUser') === true;
  const viewerCanHideUsersForNetworkInDiscovery = useSelector(getViewerCanHideUsersForNetworkInDiscovery);

  return isAdminMuteUserFlightEnabled && viewerCanHideUsersForNetworkInDiscovery;
};

type UseViewerCanManageMultiTenantOrganizationPolicy = () => boolean;
export const useViewerCanManageMultiTenantOrganizationPolicy: UseViewerCanManageMultiTenantOrganizationPolicy = () => {
  const isMTOPolicyAdminPageExperimentEnabled =
    useExperimentTreatmentWithoutReporting('MTOPolicyEngageAdminPortalPage') === true;
  const viewerCanManageMultiTenantOrganizationPolicy = useSelector(getViewerCanManageMultiTenantOrganizationPolicy);

  return isMTOPolicyAdminPageExperimentEnabled && viewerCanManageMultiTenantOrganizationPolicy;
};

type UseViewerCanManageInformationBarrierSettings = () => boolean;
export const useViewerCanManageInformationBarrierSettings: UseViewerCanManageInformationBarrierSettings = () =>
  useIsInformationBarrierEnabled();

type UseViewerCanManageSubNetworksSettings = () => boolean;
export const useViewerCanManageSubNetworksSettings: UseViewerCanManageSubNetworksSettings = () =>
  useIsSubNetworksEnabled();
