export const userSettingsRootPath = '/user-settings';
export const userSettingsGeneralSubpath = '/general';
export const userSettingsNotificationsSubpath = '/notifications';
export const userSettingsNotificationsCommunitiesSubpath = '/communities';
export const userSettingsNotificationsUnsubscribeSubpath = '/unsubscribe';
export const userSettingsNotificationsSubscribeSubpath = '/subscribe';

export const userSettingsGeneralPath = `${userSettingsRootPath}${userSettingsGeneralSubpath}`;
export const userSettingsNotificationsPath = `${userSettingsRootPath}${userSettingsNotificationsSubpath}`;
export const userSettingsNotificationsCommunitiesPath = `${userSettingsNotificationsPath}${userSettingsNotificationsCommunitiesSubpath}`;
export const userSettingsNotificationsSubscribePath = `${userSettingsNotificationsPath}${userSettingsNotificationsSubscribeSubpath}`;
export const userSettingsNotificationsUnsubscribePath = `${userSettingsNotificationsPath}${userSettingsNotificationsUnsubscribeSubpath}`;

export const userSettingsRoutes = [
  userSettingsRootPath,
  userSettingsGeneralPath,
  userSettingsNotificationsPath,
  userSettingsNotificationsCommunitiesPath,
  userSettingsNotificationsSubscribePath,
  userSettingsNotificationsUnsubscribePath,
];
