import { reportUsage } from '@ms/yammer-data/dist/telemetry';
import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import { combinePath } from '@ms/yammer-web-support/dist/location';
import React, { FC, ReactElement, memo, useEffect, useLayoutEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import PageDataLoadContextProvider from '../PageDataLoadContextProvider';
import PageRouteAssetPreloadProvider from '../PageRouteAssetPreloadProvider';
import { usePageTelemetryContextSetRouteCallback } from '../PageTelemetryContextProvider/hooks';

import {
  AppPageRoutesWithTelemetryProps,
  PageRoute,
  ParentPageRoute,
  parentRouteHasRedirectRoute,
} from './AppPageRoutesWithTelemetry.types';
import SubrouteComponentPreloader from './SubrouteComponentPreloader';
import { enforceNoDuplicatePageNames } from './enforceNoDuplicatePageNames';
import { isParentRoute } from './isParentRoute';

interface LoadablePageContentWithTelemetryProps {
  readonly route: PageRoute;
}
const LoadablePageContentWithTelemetry: FC<LoadablePageContentWithTelemetryProps> = ({ route }) => {
  const LoadablePageContent = useMemo(
    () =>
      lazyLoadOnRender({
        loader: route.loader,
      }),
    [route.loader]
  );
  const { pathname } = useLocation();

  const setRoute = usePageTelemetryContextSetRouteCallback();

  useLayoutEffect(() => {
    setRoute?.(route, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  useEffect(() => {
    reportUsage({
      eventName: 'page_visit',
      eventProperties: {
        page: route.page,
        hero: route.hero,
        path: route.path,
        urlPath: pathname,
      },
    });
  }, [pathname, route.hero, route.page, route.path]);

  return route.dataLoader ? (
    <PageDataLoadContextProvider key={pathname} dataLoader={route.dataLoader}>
      <LoadablePageContent />
    </PageDataLoadContextProvider>
  ) : (
    <LoadablePageContent />
  );
};

type GetRouteElement = (route: PageRoute) => ReactElement;
const getRouteElement: GetRouteElement = (route) => (
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact}
    render={() => <LoadablePageContentWithTelemetry route={route} />}
  />
);

type GetFallbackRedirectElement = (to: string) => ReactElement;
const getFallbackRedirectElement: GetFallbackRedirectElement = (to) => (
  <Route key={to} path="*" render={() => <Redirect to={to} />} />
);

interface LoadableParentContentProps {
  readonly parentRoute: ParentPageRoute;
}
const LoadableParentContent: FC<LoadableParentContentProps> = memo(({ parentRoute }) => {
  const LoadableParentPageContent = useMemo(
    () =>
      lazyLoadOnRender({
        loader: parentRoute.loader,
        fallback: <SubrouteComponentPreloader parentRoute={parentRoute} />,
      }),
    [parentRoute]
  );

  const { pathname } = useLocation();
  const match = useRouteMatch({
    path: parentRoute.path,
  });
  const parentRoutePath = match ? match.url : pathname;
  const routeElements = parentRoute.childRoutes.map((route) =>
    getRouteElement({ ...route, path: combinePath(parentRoute.path, route.path) })
  );

  if (parentRouteHasRedirectRoute(parentRoute)) {
    routeElements.push(getRouteElement({ ...parentRoute.fallbackRedirectRoute, path: parentRoute.path }));
  } else {
    routeElements.push(getFallbackRedirectElement(parentRoute.fallbackRedirectPath));
  }

  return parentRoute.dataLoader ? (
    <PageDataLoadContextProvider key={parentRoutePath} dataLoader={parentRoute.dataLoader}>
      <LoadableParentPageContent>
        <Switch>{routeElements}</Switch>
      </LoadableParentPageContent>
    </PageDataLoadContextProvider>
  ) : (
    <LoadableParentPageContent>
      <Switch>{routeElements}</Switch>
    </LoadableParentPageContent>
  );
});

type GetParentRouteElement = (parentRoute: ParentPageRoute) => ReactElement;
const getParentRouteElement: GetParentRouteElement = (parentRoute) => (
  <Route
    key={parentRoute.path}
    path={parentRoute.path}
    render={() => <LoadableParentContent parentRoute={parentRoute} />}
  />
);

const AppPageRoutesWithTelemetry: FC<AppPageRoutesWithTelemetryProps> = ({ routes }) => {
  enforceNoDuplicatePageNames(routes);

  const pageRoutes = routes.map((route) =>
    isParentRoute(route) ? getParentRouteElement(route) : getRouteElement(route)
  );

  return (
    <PageRouteAssetPreloadProvider routes={routes}>
      <Switch>{pageRoutes}</Switch>
    </PageRouteAssetPreloadProvider>
  );
};

export default memo(AppPageRoutesWithTelemetry);
